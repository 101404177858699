@borderColor: rgba(34, 36, 38, 0.35);

.prop-border() {
	&:focus-within input,
	&:focus-within .dropdown {
		border-color: @borderColor !important;
	}

	&:hover:not(:focus-within) input,
	&:hover:not(:focus-within) .dropdown {
		border-color: fade(@borderColor, 20%);
	}
}

.edit-member {
	width: 100%;
}

.prop-handler {
	display: flex;
	flex-direction: column;
}

.prop {
	display: flex;
	align-items: center;

	.delete-button {
		transition: 0.25s width, margin;
		width: 40px;
		margin-right: 0px;
		text-align: center;
		cursor: pointer;

		&.confirm {
			width: 95px;
			margin-right: 10px;
		}
	}

	.input-component {
		flex-grow: 1;

		.prop-border();

		input,
		.dropdown {
			border-radius: 1.4em;
			border-color: fade(@borderColor, 6%);
		}

		.dropdown + input {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		.menu {
			border-bottom-left-radius: 0.7em;
			border-bottom-right-radius: 0.7em;
		}

		.dropdown {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			border-right: none;
		}
	}
}

.edit-member {
	position: relative;

	.edit-member-header {
		display: flex;

		.edit-member-header-fields {
			flex-grow: 1;
		}
	}

	.prop-input-group {
		.prop-border();

		margin: 5px auto;

		.input {
			margin-top: 0px !important;
			margin-bottom: 0px !important;
		}

		.prop-handler:first-child:not(:only-child) {
			input {
				border-bottom-left-radius: 0 !important;
				border-bottom-right-radius: 0 !important;
			}
		}

		.prop-handler:last-child:not(:only-child) {
			.input {
				margin-top: -1px !important;
			}
			input {
				border-top-left-radius: 0 !important;
				border-top-right-radius: 0 !important;
			}
		}

		.prop-handler:not(:first-child):not(:last-child):not(:only-child) {
			.input {
				margin-top: -1px !important;
			}

			input {
				border-radius: 0 !important;
			}
		}
	}

	.prop-handler {
		.props {
		}
		.add-button {
			margin-top: 10px;
		}
	}

	.saving-dimmer-wrap {
		position: absolute;
		top: 0px;
		width: 100%;
		height: 100%;

		.saving-dimmer {
			background: rgba(255, 255, 255, 0.85);
			width: 100%;
			height: 100%;
			max-height: 100vh;
			position: sticky;
			top: 0px;
		}
	}

	.edit-member-menu {
		position: sticky;
		top: 5px;
		z-index: 10;
		height: 38px;
	}
}

.add-other-menu {
}

.edit-member-image-dimmer {
	width: 100px;
	height: 100px;
	border-radius: 50px;

	overflow: hidden;

	cursor: pointer;

	.edit-member-image {
		width: 100%;
		height: 100%;
		background-size: cover !important;
		background-position: center !important;
	}
}

.edit-member-image-file-input {
	display: none;
	width: 0px;
	height: 0px;
	position: fixed;
	margin: 0;
	padding: 0;
}

.edit-member-popup {
	margin: 0 !important;

	.item {
		margin: 0 !important;
	}
}

.ui.selection.dropdown.type-picker {
	flex-grow: 0;
	flex-shrink: 2;
	min-width: 10px;
}
