/*******************************
     User Global Variables
*******************************/
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
}
.app > .content {
  margin-bottom: 20px;
  width: 100%;
  max-width: 1000px;
}
#club-picker {
  flex-shrink: 1000;
}
#club-picker .text {
  text-overflow: ellipsis;
}
.ui.menu .ui.dropdown .menu > .item {
  margin: 5px !important;
}
#app-nav {
  margin: 10px;
}
#app-nav .no-club-selected > .text {
  color: rgba(0, 0, 0, 0.308);
}
#app-nav .no-clubs {
  color: rgba(0, 0, 0, 0.308);
}
