@import '../../semantic-ui/site/globals/site.variables';

#loaded-member {
	.quick-icons {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		color: @primaryColor;

		border-bottom: 1px solid @lightGrey;
		margin-bottom: 15px;
		padding-bottom: 15px;

		& > .quick-icon {
			display: flex;
			flex-direction: column;

			text-align: center;
		}
	}
}

@spacing: 10px;

.prop-handlers {
	.view-prop-handler {
		margin-left: @spacing;
		margin-bottom: 5px;
		.view-prop-handler-header {
			color: gray;
		}
		.view-prop-handler-component-wrap {
			margin-left: @spacing;
			.view-prop-handler-type {
			}

			.view-prop-handler-component {
				margin-left: @spacing;
				margin-bottom: 5px;

				p {
					padding: 0;
					margin: 0;
				}
			}
		}
	}
}
