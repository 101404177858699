.new-club-page,
.new-club-page-mobile {
  height: 100%;
}
.new-club-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.new-club-page .form-segment {
  width: 100%;
  max-width: 600px;
}
.new-club-page-mobile {
  padding: none;
}
.new-club-page-mobile .form-segment {
  width: 100%;
  height: 100% !important;
  padding: 0px 10px !important;
  margin: none !important;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
form div:not(.submit) {
  width: 100%;
}
.StripeElement {
  padding: 10px 10px 20px 10px;
}
