/*******************************
     User Global Variables
*******************************/
.segment-closer {
  background: white;
  transition: 0.1s background;
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 1px solid #c2c4c5;
  cursor: pointer;
}
.segment-closer:hover {
  background: rgba(220, 221, 222, 0.5);
}
.segment-closer:active {
  background: #cfd0d2;
  transition: none;
}
.segment-closer i {
  width: 1.18em;
  height: 1.24em;
  margin: 0 !important;
  padding: 0 !important;
  color: #5b5e60;
}
.ui.segment > .segment-closer {
  position: absolute;
  top: -9px;
  right: -9px;
}
