.prop-adr {
  display: flex;
  margin: 5px auto;
}
.prop-adr .ui.small.input {
  margin: 0;
}
.prop-adr .input-group {
  flex-grow: 1;
}
.prop-adr .input-group > div:not(:first-child) {
  margin-top: -1px;
}
.prop-adr .input-group .input {
  margin: 0;
}
.prop-adr .input-group > .input:nth-child(1) input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid white !important;
}
.prop-adr .input-group > .input:nth-child(2) input {
  border-radius: 0px !important;
}
.prop-adr .input-group > .input:nth-child(3) input {
  border-radius: 0px !important;
}
.prop-adr .input-group > .input:nth-child(4) input {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}
.prop-adr .input-group .input-group-horizontal {
  display: flex;
  width: 100%;
}
.prop-adr .input-group .input-group-horizontal .input {
  flex-grow: 1;
  height: 100%;
}
.prop-adr .input-group .input-group-horizontal .input input {
  border-radius: 0;
}
.prop-adr .input-group .input-group-horizontal .input:not(:first-child) {
  margin-left: -1px;
}
