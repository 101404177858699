@import '../semantic-ui/site/globals/site.variables';

.brand {
	display: flex;
	flex-direction: row;

	.brand-name {
		font-weight: bold;
		font-size: 18pt;
	}
	.beta-tag {
		font-weight: bold;
		font-size: 8pt;
	}
}
