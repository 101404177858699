@import '../semantic-ui/site/globals/site.variables';
@import '../mixins.less';

.itemcolors(@opacity) {
	background: fade(@primaryColor, @opacity);
	.inverted * & {
		background: rgba(255, 255, 255, @opacity) !important;
		color: @primaryColor !important;
	}
}

.app {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 0px 20px;

	& > .content {
		margin-bottom: 20px;
		width: 100%;
		max-width: 1000px;
	}
}

#club-picker {
	flex-shrink: 1000;

	.text {
		text-overflow: ellipsis;
	}
}

// for the clubs dropdown menu.
// the semantic default is margin: 0; for some reason...
.ui.menu .ui.dropdown .menu > .item {
	margin: 5px !important;
}

#app-nav {
	margin: 10px;

	.no-club-selected > .text {
		color: @inactiveGrey;
	}

	.no-clubs {
		color: @inactiveGrey;
	}
}
