.club-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.club {
  max-width: 1060px;
  padding: 30px;
  width: 100%;
}
.club .club-title {
  overflow-x: hidden;
  flex-shrink: 1000 !important;
}
.club .club-title h1 {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.club .member-display {
  margin-top: 30px;
}
@media (max-width: 767.98px) {
  .club {
    padding: 5px;
  }
  .club .member-display {
    margin-top: 0px;
  }
  .club .member-display-left,
  .club .member-display-right {
    margin-bottom: 30px;
  }
}
@media (max-width: 767.98px) {
  #club-selected .club-banner {
    display: none;
  }
}
