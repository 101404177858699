@import '../semantic-ui/site/globals/site.variables';

.full-page-padder {
	// height: 100vh;
	min-height: 100vh;
	height: auto;

	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	overflow: hidden;

	// background: pink;
	// outline: 1px solid black;

	&.subtract-menu {
		min-height: 0;
		height: calc(100vh - 68px); // see Storefront.less
	}
	&.subtract-footer {
		min-height: 0;
		height: calc(100vh - 25px - 20px);
	}
	&.subtract-menu.subtract-footer {
		min-height: 0;
		height: calc(100vh - 68px - 14px - 25px - 20px);
	}
	&.has-background {
		background-color: @primaryColor;
	}
	.full-page-padder-contents {
		width: 100%;
		flex-grow: 1;
		height: 100%;
		// max-height: 100vh;

		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		overflow: hidden;
	}

	&:nth-of-type(odd) {
		// background-color: rgba(0, 0, 0, 0.1);
	}

	.shadow-caster {
		@shadow-spread: 20px;

		position: absolute;

		width: calc(100% + (@shadow-spread * 2));
		margin-left: -@shadow-spread;
		height: 1px;

		// background: rgba(255, 0, 0, 0.5);

		box-shadow: 0px 0px @shadow-spread 3px rgba(0, 0, 0, 1);

		z-index: 1;

		&.top {
			top: -1px;
		}

		&.bottom {
			bottom: -1px;
		}
	}
}
