:root {
	--banner-border-rad: 30px;
	--banner-vert-margin: 10px;
}
.club-banner {
	width: 100%;
	height: 0px;
	padding-top: 40%;
	margin-left: -0px;
	border-radius: var(--banner-border-rad);
	position: relative;
	// overflow: hidden;

	& > div {
		position: absolute;
		// width: 100%;
		// height: 100%;
		top: 0px;
		color: white;
		text-shadow: 0px 2px 4px black;
	}

	.club-banner-bg {
		width: 100%;
		height: 100%;
		z-index: -1;

		border-radius: var(--banner-border-rad);
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
		}
	}
}
.club-banner-overlay {
	background: rgba(0, 0, 0, 0.2);
	transition: 0.25s background;

	width: 100%;
	height: 100%;
	// height: calc(100% + var(--banner-border-rad)) !important;
	// margin-top: calc(-1 * var(--banner-border-rad));

	box-shadow: inset 0px 0px 30px 0px rgba(0, 0, 0, 0.5);

	border-radius: inherit;
}

.club-banner-content {
	position: relative;
	display: flex;
	box-sizing: border-box;
	margin: var(--banner-border-rad);
	margin-top: var(--banner-vert-margin);
	margin-bottom: var(--banner-vert-margin);
	width: calc(100% - (2 * var(--banner-border-rad))) !important;
	height: calc(100% - var(--banner-vert-margin) * 2) !important;
}
