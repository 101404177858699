/*******************************
     User Global Variables
*******************************/
.letter-header {
  background: coral;
  font-size: 14pt;
  padding: 5px;
  position: sticky !important;
  top: 50px;
  border-radius: 11em;
  padding-left: 0.75em;
  line-height: 1em;
  z-index: 2;
}
#member-list .member-list-menu {
  position: sticky !important;
  top: 5px;
  z-index: 10;
  margin-bottom: 5px;
}
#member-list .member-list-menu .left.menu {
  flex-grow: 1;
  cursor: text;
}
#member-list .member-list-menu div.search {
  margin-left: 10px;
  flex-grow: 1;
  display: flex;
  align-items: center;
}
#member-list .member-list-menu div.search input {
  margin: 0px 5px;
}
#member-list .member-list-menu div.search .x.link.icon {
  cursor: pointer;
}
#member-list .list {
  padding: 0;
}
#member-list .image {
  flex-shrink: 0;
}
#member-list .item {
  padding: 10px;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
#member-list .item div,
#member-list .item img {
  z-index: 1;
}
#member-list .item img {
  border: 2px solid #cfd0d2;
}
#member-list .item .ui.image {
  padding-right: 0;
}
#member-list .item .header {
  font-weight: normal;
  font-size: 13pt;
}
#member-list .item .member-name {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
#member-list .item .member-name * {
  width: 100%;
  white-space: nowrap;
  min-width: 0;
}
#member-list .item > .right.floated.content {
  display: flex;
  align-items: center;
  height: 100% !important;
  float: none;
  flex-grow: 0;
}
#member-list .item > .right.floated.content .email-label {
  display: flex;
  align-items: baseline;
  font-size: 9pt;
  margin-right: 5px;
  color: #a0a0a0;
}
#member-list .item .member-bg {
  box-sizing: border-box;
  width: calc(100% - 2px * 2);
  height: calc(100% - 5px * 2);
  margin-left: -8px;
  position: absolute;
  border-radius: 4em;
  border: none;
  z-index: 1;
  transition: 0.1s background;
}
#member-list .item:hover .member-bg {
  background: rgba(220, 221, 222, 0.5);
}
#member-list .item:active .member-bg {
  background: #c2c4c5;
  transition: none;
}
#member-list #member-selected .member-bg {
  background: #dcddde;
}
.label-entry-list-header {
  color: gray;
  margin: 5px;
}
.label-entry-list {
  display: flex;
  flex-wrap: wrap;
}
.label-entry-list .entry-label {
  cursor: pointer;
}
.label-entry-list .entry-label.selected {
  background: #dcddde;
}
