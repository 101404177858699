.visitor-route {
	// overflow-y: hidden;
	// height: 100vh;
}

.ui.modal {
	width: auto !important;
}

.password-header {
	text-align: center;
}

// .visitor-password-input input {
// 	background: none !important;
// 	border-color: white !important;
// 	color: white !important;
// }

.visitor-password {
	min-width: 300px;
	input {
		width: auto !important;
	}
}

.visitor-password.mobile {
	top: 10px;
}
