.ui.modal {
  width: auto !important;
}
.password-header {
  text-align: center;
}
.visitor-password {
  min-width: 300px;
}
.visitor-password input {
  width: auto !important;
}
.visitor-password.mobile {
  top: 10px;
}
