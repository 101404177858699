@import '../../mixins';
@import '../../semantic-ui/site/globals/site.variables';

.editor-email-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.editor-email-segment {
	margin: 30px !important;
	width: 85% !important;
}

.editor-email {
	display: flex;
	align-items: center;
	width: 100%;

	span {
		font-weight: bold;
	}

	.editor-email-input {
		flex-grow: 1;
		margin: 0px 14px;
	}

	i {
		align-self: center;
		margin: 0;
	}
}

.editor-email-basic {
	color: @grayish;
	text-align: center;
	cursor: pointer;

	padding: 10px 30px;
	border-radius: 30pt;

	transition: 0.1s background;

	&:hover {
		background: fade(@listSelectionColor, 50%);
	}

	&:active {
		background: darken(@listSelectionColor, 5%);
		transition: none;
	}

	.editor-email-basic-header {
		font-size: 12pt;
	}

	.editor-email-basic-email {
		font-size: 18pt;
		margin-top: 5px;
	}
}
