.club-banner {
	// h1 {
	// 	color: rgba(255, 255, 255, 0.7);
	// }

	.club-title {
		overflow-x: hidden;
		flex-shrink: 1000 !important;

		h1 {
			width: 100%;
			overflow-x: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.banner-header {
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		background: red;
	}
	.banner-menu {
		height: 40px;
		width: 100%;
	}

	.banner-upload {
		position: absolute;
		bottom: 15px;
		left: 0px;

		input {
			display: none;
		}
	}

	* {
		transition: 0.3s color background;
	}

	.ui.basic.buttons {
		border: none;
	}

	// .ui.secondary.inverted.menu a.item {
	// 	color: rgba(255, 255, 255, 1) !important;
	// }

	&.administrator:hover {
		.club-banner-overlay {
			background: rgba(0, 0, 0, 0.5);
		}

		// *,
		// .ui.secondary.inverted.menu a.item {
		// 	color: rgba(255, 255, 255, 1) !important;
		// }
	}
}

.confirm-delete-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 50vh;
}

.confirm-delete {
	width: 400px;
	text-align: center;
}

.create-modal {
	width: 100vw;
	max-width: 645px;
	padding: 20px;
}

.create-from-emails {
	display: flex;
	flex-direction: column;
	align-items: center;

	.emails-wrap {
		width: 100%;

		.ui.label {
			margin: 3px;
		}
	}

	.no-emails {
		color: gray;
		text-align: center;
	}

	form {
		width: 100%;
	}
}

.create-from-csv {
	display: flex;
	flex-direction: column;
	align-items: center;
}
