@import '../../mixins.less';

.add-button-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-grow: 1;
}

.add-button {
	padding: 11px 21px;
	padding-right: 28px;
	border-radius: 28px;

	transition: 0.3s background;

	display: flex;
	align-items: center;

	flex-grow: 0;

	cursor: pointer;

	&:hover {
		background: #efeff0;
	}

	&:active {
		.add-button {
			background: #dcddde;
			transition: 0s;
		}
	}

	i {
		color: #21ba45;
	}

	span {
		// width: 0px;
		// .mobile({width: auto;});
		transition: 0.25s width;
		white-space: nowrap;
		overflow: hidden;
	}
}
