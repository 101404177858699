.prop-date {
  display: flex;
  margin: 5px auto;
}
.prop-date .input {
  margin: 0 !important;
  flex-grow: 1;
}
.prop-date .input:not(:first-child) {
  margin-left: -1px !important;
}
.prop-date .input:nth-child(1) input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.prop-date .input:nth-child(2) input {
  border-radius: 0;
}
.prop-date .input:nth-child(3) input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
