/*******************************
     User Global Variables
*******************************/
#loaded-member .quick-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: coral;
  border-bottom: 1px solid #dcddde;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
#loaded-member .quick-icons > .quick-icon {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.prop-handlers .view-prop-handler {
  margin-left: 10px;
  margin-bottom: 5px;
}
.prop-handlers .view-prop-handler .view-prop-handler-header {
  color: gray;
}
.prop-handlers .view-prop-handler .view-prop-handler-component-wrap {
  margin-left: 10px;
}
.prop-handlers .view-prop-handler .view-prop-handler-component-wrap .view-prop-handler-component {
  margin-left: 10px;
  margin-bottom: 5px;
}
.prop-handlers .view-prop-handler .view-prop-handler-component-wrap .view-prop-handler-component p {
  padding: 0;
  margin: 0;
}
