/*******************************
     User Global Variables
*******************************/
.features-wrap {
  position: relative;
  width: 100%;
  flex-grow: 1;
  display: flex;
  padding: 20px 0px;
  z-index: 0;
}
.features {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  align-content: space-around;
  justify-content: space-evenly;
}
.feature {
  width: 100%;
  max-width: 33%;
  min-width: 275px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feature i.icon {
  margin: 10px;
  margin-bottom: 20px;
}
.feature .feature-card {
  background: white;
  width: 100%;
  flex-grow: 1;
  width: 275px;
  padding: 10px;
}
.feature .feature-card .feature-title {
  font-size: 24pt;
  text-align: center;
  margin: 10px;
}
.feature .feature-card p {
  text-align: center;
}
