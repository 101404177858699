@import '../semantic-ui/site/globals/site.variables';

@storefrontWidth: 1200px;
@storefrontPadding: 14px;

.storefront-width-wrap {
	max-width: @storefrontWidth;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0px @storefrontPadding;
}

.storefront {
	display: flex;
	flex-direction: column;
	align-items: center;

	margin: 0 auto;

	min-height: 100vh;

	> * {
		// flex-grow: 1;
		width: 100%;
	}

	.item {
		padding: 0;
	}

	.full-page-padder-contents {
		padding: 0px;
		// max-width: @storefrontWidth;
	}
}

.menu-item {
	font-size: 14pt;
}

.menu-item-active {
	color: @primaryColor !important;
}

.storefront-nav {
	// height: 68px;
	max-width: @storefrontWidth;
	// margin: 0px 0px 14px !important;

	display: flex;
	align-items: center;

	flex-grow: 1;
	width: 100%;

	a {
		color: grey;
	}
}

#login-button {
	color: @primaryColor !important;
	font-weight: 500;
}

.centerizer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-grow: 1;

	&.left {
		align-items: flex-start;
	}

	&.right {
		align-items: flex-end;
	}
}

.billboard {
	h1 {
		font-size: 36pt;
		color: @primaryColor;
	}

	p {
		font-size: 13.5pt;
	}
}

// on backgrounds

.full-page-padder.has-background {
	h1 {
		color: white;
		text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
	}

	p {
		color: white;
	}
}

#storefront-footer {
	margin: 15px;
	text-align: center;
	color: @inactiveGrey;

	a {
		color: fadein(@inactiveGrey, 10%);
		text-decoration: underline;
	}

	> *:not(:first-child)::before {
		content: '—';
		margin: 0px 10px;
		text-decoration: none !important;
	}
}
