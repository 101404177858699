/*******************************
     User Global Variables
*******************************/
.login-panel .login-button button {
  width: 100%;
  margin: 0;
}
.login-panel .ui.form .field {
  margin: 0px 0px 14px;
}
.login-panel .ui.form .field .ui.input {
  margin: 0;
}
.login-panel .alt-links {
  text-align: center;
  text-decoration: underline;
}
.login-panel .alt-links a {
  color: rgba(0, 0, 0, 0.308) !important;
}
.login-panel .alt-links a:not(:first-child) {
  margin-left: 5px;
}
.login-panel .extra-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}
