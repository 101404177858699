@import '../../semantic-ui/site/globals/site.variables';

@yes-green: rgb(78, 214, 73);

@lift-height: 5px;

.pricing-wrap {
	display: flex;
	flex-wrap: wrap;
	align-content: space-around;
	justify-content: space-evenly;
}

.pricing-plan {
	line-height: normal;

	max-width: 300px;

	margin: 8px;

	cursor: pointer;

	&:hover .pricing-plan-lift,
	&:active .pricing-plan-lift {
		transform: translateY(-1 * @lift-height);
	}

	.pricing-plan-name {
		text-align: center;
		font-weight: 500;
		font-size: 18pt;
		color: white;
		width: 100%;

		margin-bottom: 5px + @lift-height;
	}

	.pricing-plan-subtitle {
		color: white;
		font-size: 11pt;
		padding: 0px 26px;
		font-weight: normal;
		font-style: italic;
	}

	.pricing-plan-lift {
		display: flex;
		flex-direction: column;
		align-items: center;
		transition: 0.25s transform;
		color: rgba(0, 0, 0, 0.87);
	}

	.pricing-plan-card {
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

		text-align: center;
		margin-bottom: 0px;

		.pricing-plan-free-trial {
			header {
				font-size: 28pt;
				color: @yes-green;
				font-weight: 500;
			}
			div {
				font-size: 28pt;
			}
		}

		.pricing-plan-price {
			span {
				font-size: 28pt;
				font-weight: 500;
			}

			div {
				font-size: 12pt;
			}
		}
	}

	.pricing-plan-signup {
		color: white;
		background-color: darken(@yes-green, 15%);
		width: 75%;
		padding: 20px 0px;
		font-size: 16pt;
		white-space: nowrap;
		text-align: center;

		@borderRadius: 10pt;
		border-bottom-left-radius: @borderRadius;
		border-bottom-right-radius: @borderRadius;

		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	}
}
