@import '../../mixins.less';

.member-display {
	display: flex;
	width: 100%;
	flex-direction: row;

	min-height: 600px;

	[class^='member-display-'] {
		flex-grow: 1;
		width: 50%;
	}
}
// TODO fix spacing between columns
@columnSpacing: 5px;

.member-display-left,
.member-display-right {
	background: white;
}

.member-display-left {
	// background: red;
	padding-right: @columnSpacing / 2;
}

.member-display-right {
	// background: green;
	// display: none;
	padding-left: @columnSpacing / 2;
	z-index: 10;
	top: 0px;

	// TODO please, get rid of this as fast as possible, I beg you
	& > div[style='position: sticky; top: 10px;'] {
		top: 0px !important;
	}
}

.entry-label {
	margin: 1.7px !important;
}

@media (max-width: @mobile-width) {
	.member-display {
		position: relative;

		padding-top: 5px;

		& .member-display-left,
		& .member-display-right {
			position: absolute;

			width: 100%;
			padding-left: 0;
			padding-right: 0;
		}

		&.selected .member-display-left {
			display: none;
		}
	}
}
