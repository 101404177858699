.prop-adr {
	display: flex;

	margin: 5px auto;

	.ui.small.input {
		margin: 0;
	}

	.input-group {
		flex-grow: 1;

		& > div:not(:first-child) {
			margin-top: -1px;
		}

		.input {
			margin: 0;
		}

		& > .input {
			&:nth-child(1) input {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				border-bottom: 1px solid white !important;
			}

			&:nth-child(2) input {
				border-radius: 0px !important;
			}

			&:nth-child(3) input {
				border-radius: 0px !important;
			}

			&:nth-child(4) input {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				margin-top: -1px;
			}
		}

		.input-group-horizontal {
			display: flex;
			width: 100%;

			.input {
				flex-grow: 1;
				height: 100%;
				input {
					border-radius: 0;
				}

				&:not(:first-child) {
					margin-left: -1px;
				}
			}
		}
	}
}
