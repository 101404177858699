.flex-center {
	display: flex;
	flex-direction: column;
	align-items: center;
}

#forgot-password-form {
	width: 100%;
	max-width: 400px;

	padding: 0px 8px;

	.ui.button {
		margin: 0;
	}

	.ui.input {
		margin: 0;
	}
}
