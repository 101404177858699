.editor-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0px;
}
.editor-view .edit-member {
  padding: 0px 10px;
  max-width: 500px;
}
