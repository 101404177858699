@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@media (max-width: 767.98px) {
  .ui.sidebar.bottom {
    border-radius: 1em 1em 0px 0px !important;
  }
}
body {
  margin: 0;
  overflow: auto;
}
#root {
  width: 100%;
}
code {
  font-family: 'Roboto Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.nav-link {
  white-space: nowrap;
}
#root .ui-alerts.top-center {
  left: 0;
  margin-left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#root .ui-alerts.top-center div {
  max-width: 444px;
}
