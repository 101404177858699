#home {
	display: flex;
	flex-direction: row;
	width: 100%;
	max-width: 1200px;
	flex-grow: 1;
	padding: 50px 50px;
	min-height: 700px;
	justify-content: center;
	align-content: center;
	align-items: center;
	height: 100%;

	.billboard {
		flex-grow: 1;
		// background: red;
	}

	.left.billboard {
		padding-right: 4px;
	}
}

#signup-box {
	max-width: 300px;

	display: flex;
	flex-direction: column;
	justify-content: center;
}

@media (max-width: 800px) {
	#home {
		flex-direction: column;
		justify-content: space-evenly;

		.billboard {
			// background: pink;
			align-items: center;
			text-align: center;
			flex-grow: 0;
		}

		.left.billboard {
			padding-right: 0px;
		}
	}

	#signup-box {
	}
}
