/*******************************
     User Global Variables
*******************************/
.storefront-width-wrap {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 14px;
}
.storefront {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  min-height: 100vh;
}
.storefront > * {
  width: 100%;
}
.storefront .item {
  padding: 0;
}
.storefront .full-page-padder-contents {
  padding: 0px;
}
.menu-item {
  font-size: 14pt;
}
.menu-item-active {
  color: coral !important;
}
.storefront-nav {
  max-width: 1200px;
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}
.storefront-nav a {
  color: grey;
}
#login-button {
  color: coral !important;
  font-weight: 500;
}
.centerizer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
.centerizer.left {
  align-items: flex-start;
}
.centerizer.right {
  align-items: flex-end;
}
.billboard h1 {
  font-size: 36pt;
  color: coral;
}
.billboard p {
  font-size: 13.5pt;
}
.full-page-padder.has-background h1 {
  color: white;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
}
.full-page-padder.has-background p {
  color: white;
}
#storefront-footer {
  margin: 15px;
  text-align: center;
  color: rgba(0, 0, 0, 0.308);
}
#storefront-footer a {
  color: rgba(0, 0, 0, 0.408);
  text-decoration: underline;
}
#storefront-footer > *:not(:first-child)::before {
  content: '—';
  margin: 0px 10px;
  text-decoration: none !important;
}
