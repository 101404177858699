.club-settings {
	margin: 30px 0px;
}

.ui.checkbox,
.ui.input,
.ui.button {
	margin: 5px 5px;
}

.pay-button {
	display: flex;
	flex-direction: column;
	align-items: center;

	p {
		font-size: 8pt;
		font-style: italic;
		color: gray;
	}
}
