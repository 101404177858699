/*******************************
     User Global Variables
*******************************/
.brand {
  display: flex;
  flex-direction: row;
}
.brand .brand-name {
  font-weight: bold;
  font-size: 18pt;
}
.brand .beta-tag {
  font-weight: bold;
  font-size: 8pt;
}
