.club-banner .club-title {
  overflow-x: hidden;
  flex-shrink: 1000 !important;
}
.club-banner .club-title h1 {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.club-banner .banner-header {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  background: red;
}
.club-banner .banner-menu {
  height: 40px;
  width: 100%;
}
.club-banner .banner-upload {
  position: absolute;
  bottom: 15px;
  left: 0px;
}
.club-banner .banner-upload input {
  display: none;
}
.club-banner * {
  transition: 0.3s color background;
}
.club-banner .ui.basic.buttons {
  border: none;
}
.club-banner.administrator:hover .club-banner-overlay {
  background: rgba(0, 0, 0, 0.5);
}
.confirm-delete-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}
.confirm-delete {
  width: 400px;
  text-align: center;
}
.create-modal {
  width: 100vw;
  max-width: 645px;
  padding: 20px;
}
.create-from-emails {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.create-from-emails .emails-wrap {
  width: 100%;
}
.create-from-emails .emails-wrap .ui.label {
  margin: 3px;
}
.create-from-emails .no-emails {
  color: gray;
  text-align: center;
}
.create-from-emails form {
  width: 100%;
}
.create-from-csv {
  display: flex;
  flex-direction: column;
  align-items: center;
}
