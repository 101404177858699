@import '../../semantic-ui/site/globals/site.variables';

@spacing: 10px;

.features-wrap {
	position: relative;

	width: 100%;
	flex-grow: 1;
	display: flex;

	padding: 20px 0px;
	// background: @primaryColor;
	z-index: 0;
}

.features {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	flex-grow: 1;
	// align-items: center;
	align-content: space-around;
	justify-content: space-evenly;
}

.feature {
	width: 100%;
	max-width: 33%;
	min-width: 275px;

	// flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-items: center;

	i.icon {
		margin: @spacing;
		margin-bottom: @spacing * 2;
	}

	.feature-card {
		background: white;
		width: 100%;
		flex-grow: 1;

		width: 275px;

		padding: @spacing;

		// border: 1px solid greyscale(#c0c0c0);
		// border-radius: 16px;
		// box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);

		.feature-title {
			font-size: 24pt;
			text-align: center;
			margin: @spacing;
		}

		p {
			text-align: center;
		}
	}
}
