@import '../semantic-ui/site/globals/site.variables';

@size: 20px;
@inset: 1px;

.segment-closer {
	background: white;
	transition: 0.1s background;

	width: @size;
	height: @size;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	border-radius: @size;

	border: 1px solid darken(@lightGrey, 10%);

	cursor: pointer;

	&:hover {
		background: fade(@lightGrey, 50%);
	}

	&:active {
		background: darken(@lightGrey, 5%);
		transition: none;
	}

	i {
		width: 1.18em;
		height: 1.24em;
		margin: 0 !important;
		padding: 0 !important;

		color: darken(@lightGrey, 50%);
	}
}

.ui.segment > .segment-closer {
	position: absolute;
	top: (@size / 2 * -1) + @inset;
	right: (@size / 2 * -1) + @inset;
}
