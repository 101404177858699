#login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
#login-page .login-panel {
  width: 100%;
  max-width: 345px;
}
