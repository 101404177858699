/*******************************
     User Global Variables
*******************************/
.editor-email-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.editor-email-segment {
  margin: 30px !important;
  width: 85% !important;
}
.editor-email {
  display: flex;
  align-items: center;
  width: 100%;
}
.editor-email span {
  font-weight: bold;
}
.editor-email .editor-email-input {
  flex-grow: 1;
  margin: 0px 14px;
}
.editor-email i {
  align-self: center;
  margin: 0;
}
.editor-email-basic {
  color: #a0a0a0;
  text-align: center;
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 30pt;
  transition: 0.1s background;
}
.editor-email-basic:hover {
  background: rgba(220, 221, 222, 0.5);
}
.editor-email-basic:active {
  background: #cfd0d2;
  transition: none;
}
.editor-email-basic .editor-email-basic-header {
  font-size: 12pt;
}
.editor-email-basic .editor-email-basic-email {
  font-size: 18pt;
  margin-top: 5px;
}
