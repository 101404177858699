@import '../semantic-ui/site/globals/site.variables';

.login-panel {
	.login-button button {
		width: 100%;
		margin: 0;
	}

	.ui.form .field {
		margin: 0px 0px 14px;

		.ui.input {
			margin: 0;
		}
	}

	.alt-links {
		text-align: center;
		text-decoration: underline;

		a {
			color: @inactiveGrey !important;

			&:not(:first-child) {
				margin-left: 5px;
			}
		}
	}

	.extra-buttons {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
