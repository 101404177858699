@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@media (max-width: 767.98px) {
  .ui.sidebar.bottom {
    border-radius: 1em 1em 0px 0px !important;
  }
}
body {
  margin: 0;
  overflow: auto;
}
#root {
  width: 100%;
}
code {
  font-family: 'Roboto Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.nav-link {
  white-space: nowrap;
}
#root .ui-alerts.top-center {
  left: 0;
  margin-left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#root .ui-alerts.top-center div {
  max-width: 444px;
}

/*******************************
     User Global Variables
*******************************/
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
}
.app > .content {
  margin-bottom: 20px;
  width: 100%;
  max-width: 1000px;
}
#club-picker {
  flex-shrink: 1000;
}
#club-picker .text {
  text-overflow: ellipsis;
}
.ui.menu .ui.dropdown .menu > .item {
  margin: 5px !important;
}
#app-nav {
  margin: 10px;
}
#app-nav .no-club-selected > .text {
  color: rgba(0, 0, 0, 0.308);
}
#app-nav .no-clubs {
  color: rgba(0, 0, 0, 0.308);
}

.code-block {
  overflow-x: auto;
  background: lightgray !important;
  box-sizing: border-box;
  max-width: 100%;
}
pre {
  min-height: 100px;
}

.settings-menu {
  margin: 30px 0px;
}

.new-club-page,
.new-club-page-mobile {
  height: 100%;
}
.new-club-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.new-club-page .form-segment {
  width: 100%;
  max-width: 600px;
}
.new-club-page-mobile {
  padding: none;
}
.new-club-page-mobile .form-segment {
  width: 100%;
  height: 100% !important;
  padding: 0px 10px !important;
  margin: none !important;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
form div:not(.submit) {
  width: 100%;
}
.StripeElement {
  padding: 10px 10px 20px 10px;
}

.club-banner .club-title {
  overflow-x: hidden;
  flex-shrink: 1000 !important;
}
.club-banner .club-title h1 {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.club-banner .banner-header {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  background: red;
}
.club-banner .banner-menu {
  height: 40px;
  width: 100%;
}
.club-banner .banner-upload {
  position: absolute;
  bottom: 15px;
  left: 0px;
}
.club-banner .banner-upload input {
  display: none;
}
.club-banner * {
  -webkit-transition: 0.3s color background;
  transition: 0.3s color background;
}
.club-banner .ui.basic.buttons {
  border: none;
}
.club-banner.administrator:hover .club-banner-overlay {
  background: rgba(0, 0, 0, 0.5);
}
.confirm-delete-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}
.confirm-delete {
  width: 400px;
  text-align: center;
}
.create-modal {
  width: 100vw;
  max-width: 645px;
  padding: 20px;
}
.create-from-emails {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.create-from-emails .emails-wrap {
  width: 100%;
}
.create-from-emails .emails-wrap .ui.label {
  margin: 3px;
}
.create-from-emails .no-emails {
  color: gray;
  text-align: center;
}
.create-from-emails form {
  width: 100%;
}
.create-from-csv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

:root {
  --banner-border-rad: 30px;
  --banner-vert-margin: 10px;
}
.club-banner {
  width: 100%;
  height: 0px;
  padding-top: 40%;
  margin-left: 0px;
  border-radius: 30px;
  border-radius: var(--banner-border-rad);
  position: relative;
}
.club-banner > div {
  position: absolute;
  top: 0px;
  color: white;
  text-shadow: 0px 2px 4px black;
}
.club-banner .club-banner-bg {
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 30px;
  border-radius: var(--banner-border-rad);
  overflow: hidden;
}
.club-banner .club-banner-bg img {
  width: 100%;
  height: 100%;
}
.club-banner-overlay {
  background: rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.25s background;
  transition: 0.25s background;
  width: 100%;
  height: 100%;
  box-shadow: inset 0px 0px 30px 0px rgba(0, 0, 0, 0.5);
  border-radius: inherit;
}
.club-banner-content {
  position: relative;
  display: flex;
  box-sizing: border-box;
  margin: 30px;
  margin: var(--banner-border-rad);
  margin-top: 10px;
  margin-top: var(--banner-vert-margin);
  margin-bottom: 10px;
  margin-bottom: var(--banner-vert-margin);
  width: calc(100% - (2 * 30px)) !important;
  width: calc(100% - (2 * var(--banner-border-rad))) !important;
  height: calc(100% - 10px * 2) !important;
  height: calc(100% - var(--banner-vert-margin) * 2) !important;
}

.member-display {
  display: flex;
  width: 100%;
  flex-direction: row;
  min-height: 600px;
}
.member-display [class^='member-display-'] {
  flex-grow: 1;
  width: 50%;
}
.member-display-left,
.member-display-right {
  background: white;
}
.member-display-left {
  padding-right: 2.5px;
}
.member-display-right {
  padding-left: 2.5px;
  z-index: 10;
  top: 0px;
}
.member-display-right > div[style='position: sticky; top: 10px;'] {
  top: 0px !important;
}
.entry-label {
  margin: 1.7px !important;
}
@media (max-width: 767.98px) {
  .member-display {
    position: relative;
    padding-top: 5px;
  }
  .member-display .member-display-left,
  .member-display .member-display-right {
    position: absolute;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .member-display.selected .member-display-left {
    display: none;
  }
}

.edit-member {
  width: 100%;
}
.prop-handler {
  display: flex;
  flex-direction: column;
}
.prop {
  display: flex;
  align-items: center;
}
.prop .delete-button {
  -webkit-transition: 0.25s width, margin;
  transition: 0.25s width, margin;
  width: 40px;
  margin-right: 0px;
  text-align: center;
  cursor: pointer;
}
.prop .delete-button.confirm {
  width: 95px;
  margin-right: 10px;
}
.prop .input-component {
  flex-grow: 1;
}
.prop .input-component:focus-within input,
.prop .input-component:focus-within .dropdown {
  border-color: rgba(34, 36, 38, 0.35) !important;
}
.prop .input-component:hover:not(:focus-within) input,
.prop .input-component:hover:not(:focus-within) .dropdown {
  border-color: rgba(34, 36, 38, 0.2);
}
.prop .input-component input,
.prop .input-component .dropdown {
  border-radius: 1.4em;
  border-color: rgba(34, 36, 38, 0.06);
}
.prop .input-component .dropdown + input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.prop .input-component .menu {
  border-bottom-left-radius: 0.7em;
  border-bottom-right-radius: 0.7em;
}
.prop .input-component .dropdown {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}
.edit-member {
  position: relative;
}
.edit-member .edit-member-header {
  display: flex;
}
.edit-member .edit-member-header .edit-member-header-fields {
  flex-grow: 1;
}
.edit-member .prop-input-group {
  margin: 5px auto;
}
.edit-member .prop-input-group:focus-within input,
.edit-member .prop-input-group:focus-within .dropdown {
  border-color: rgba(34, 36, 38, 0.35) !important;
}
.edit-member .prop-input-group:hover:not(:focus-within) input,
.edit-member .prop-input-group:hover:not(:focus-within) .dropdown {
  border-color: rgba(34, 36, 38, 0.2);
}
.edit-member .prop-input-group .input {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.edit-member .prop-input-group .prop-handler:first-child:not(:only-child) input {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.edit-member .prop-input-group .prop-handler:last-child:not(:only-child) .input {
  margin-top: -1px !important;
}
.edit-member .prop-input-group .prop-handler:last-child:not(:only-child) input {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.edit-member .prop-input-group .prop-handler:not(:first-child):not(:last-child):not(:only-child) .input {
  margin-top: -1px !important;
}
.edit-member .prop-input-group .prop-handler:not(:first-child):not(:last-child):not(:only-child) input {
  border-radius: 0 !important;
}
.edit-member .prop-handler .add-button {
  margin-top: 10px;
}
.edit-member .saving-dimmer-wrap {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
}
.edit-member .saving-dimmer-wrap .saving-dimmer {
  background: rgba(255, 255, 255, 0.85);
  width: 100%;
  height: 100%;
  max-height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
}
.edit-member .edit-member-menu {
  position: -webkit-sticky;
  position: sticky;
  top: 5px;
  z-index: 10;
  height: 38px;
}
.edit-member-image-dimmer {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  overflow: hidden;
  cursor: pointer;
}
.edit-member-image-dimmer .edit-member-image {
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center !important;
}
.edit-member-image-file-input {
  display: none;
  width: 0px;
  height: 0px;
  position: fixed;
  margin: 0;
  padding: 0;
}
.edit-member-popup {
  margin: 0 !important;
}
.edit-member-popup .item {
  margin: 0 !important;
}
.ui.selection.dropdown.type-picker {
  flex-grow: 0;
  flex-shrink: 2;
  min-width: 10px;
}

.add-button-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}
.add-button {
  padding: 11px 21px;
  padding-right: 28px;
  border-radius: 28px;
  -webkit-transition: 0.3s background;
  transition: 0.3s background;
  display: flex;
  align-items: center;
  flex-grow: 0;
  cursor: pointer;
}
.add-button:hover {
  background: #efeff0;
}
.add-button:active .add-button {
  background: #dcddde;
  -webkit-transition: 0s;
  transition: 0s;
}
.add-button i {
  color: #21ba45;
}
.add-button span {
  -webkit-transition: 0.25s width;
  transition: 0.25s width;
  white-space: nowrap;
  overflow: hidden;
}

.prop-adr {
  display: flex;
  margin: 5px auto;
}
.prop-adr .ui.small.input {
  margin: 0;
}
.prop-adr .input-group {
  flex-grow: 1;
}
.prop-adr .input-group > div:not(:first-child) {
  margin-top: -1px;
}
.prop-adr .input-group .input {
  margin: 0;
}
.prop-adr .input-group > .input:nth-child(1) input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid white !important;
}
.prop-adr .input-group > .input:nth-child(2) input {
  border-radius: 0px !important;
}
.prop-adr .input-group > .input:nth-child(3) input {
  border-radius: 0px !important;
}
.prop-adr .input-group > .input:nth-child(4) input {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}
.prop-adr .input-group .input-group-horizontal {
  display: flex;
  width: 100%;
}
.prop-adr .input-group .input-group-horizontal .input {
  flex-grow: 1;
  height: 100%;
}
.prop-adr .input-group .input-group-horizontal .input input {
  border-radius: 0;
}
.prop-adr .input-group .input-group-horizontal .input:not(:first-child) {
  margin-left: -1px;
}

.prop-date {
  display: flex;
  margin: 5px auto;
}
.prop-date .input {
  margin: 0 !important;
  flex-grow: 1;
}
.prop-date .input:not(:first-child) {
  margin-left: -1px !important;
}
.prop-date .input:nth-child(1) input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.prop-date .input:nth-child(2) input {
  border-radius: 0;
}
.prop-date .input:nth-child(3) input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/*******************************
     User Global Variables
*******************************/
.editor-email-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.editor-email-segment {
  margin: 30px !important;
  width: 85% !important;
}
.editor-email {
  display: flex;
  align-items: center;
  width: 100%;
}
.editor-email span {
  font-weight: bold;
}
.editor-email .editor-email-input {
  flex-grow: 1;
  margin: 0px 14px;
}
.editor-email i {
  align-self: center;
  margin: 0;
}
.editor-email-basic {
  color: #a0a0a0;
  text-align: center;
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 30pt;
  -webkit-transition: 0.1s background;
  transition: 0.1s background;
}
.editor-email-basic:hover {
  background: rgba(220, 221, 222, 0.5);
}
.editor-email-basic:active {
  background: #cfd0d2;
  -webkit-transition: none;
  transition: none;
}
.editor-email-basic .editor-email-basic-header {
  font-size: 12pt;
}
.editor-email-basic .editor-email-basic-email {
  font-size: 18pt;
  margin-top: 5px;
}

/*******************************
     User Global Variables
*******************************/
.segment-closer {
  background: white;
  -webkit-transition: 0.1s background;
  transition: 0.1s background;
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 1px solid #c2c4c5;
  cursor: pointer;
}
.segment-closer:hover {
  background: rgba(220, 221, 222, 0.5);
}
.segment-closer:active {
  background: #cfd0d2;
  -webkit-transition: none;
  transition: none;
}
.segment-closer i {
  width: 1.18em;
  height: 1.24em;
  margin: 0 !important;
  padding: 0 !important;
  color: #5b5e60;
}
.ui.segment > .segment-closer {
  position: absolute;
  top: -9px;
  right: -9px;
}

/*******************************
     User Global Variables
*******************************/
#loaded-member .quick-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: coral;
  border-bottom: 1px solid #dcddde;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
#loaded-member .quick-icons > .quick-icon {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.prop-handlers .view-prop-handler {
  margin-left: 10px;
  margin-bottom: 5px;
}
.prop-handlers .view-prop-handler .view-prop-handler-header {
  color: gray;
}
.prop-handlers .view-prop-handler .view-prop-handler-component-wrap {
  margin-left: 10px;
}
.prop-handlers .view-prop-handler .view-prop-handler-component-wrap .view-prop-handler-component {
  margin-left: 10px;
  margin-bottom: 5px;
}
.prop-handlers .view-prop-handler .view-prop-handler-component-wrap .view-prop-handler-component p {
  padding: 0;
  margin: 0;
}

/*******************************
     User Global Variables
*******************************/
.letter-header {
  background: coral;
  font-size: 14pt;
  padding: 5px;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 50px;
  border-radius: 11em;
  padding-left: 0.75em;
  line-height: 1em;
  z-index: 2;
}
#member-list .member-list-menu {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 5px;
  z-index: 10;
  margin-bottom: 5px;
}
#member-list .member-list-menu .left.menu {
  flex-grow: 1;
  cursor: text;
}
#member-list .member-list-menu div.search {
  margin-left: 10px;
  flex-grow: 1;
  display: flex;
  align-items: center;
}
#member-list .member-list-menu div.search input {
  margin: 0px 5px;
}
#member-list .member-list-menu div.search .x.link.icon {
  cursor: pointer;
}
#member-list .list {
  padding: 0;
}
#member-list .image {
  flex-shrink: 0;
}
#member-list .item {
  padding: 10px;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
#member-list .item div,
#member-list .item img {
  z-index: 1;
}
#member-list .item img {
  border: 2px solid #cfd0d2;
}
#member-list .item .ui.image {
  padding-right: 0;
}
#member-list .item .header {
  font-weight: normal;
  font-size: 13pt;
}
#member-list .item .member-name {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
#member-list .item .member-name * {
  width: 100%;
  white-space: nowrap;
  min-width: 0;
}
#member-list .item > .right.floated.content {
  display: flex;
  align-items: center;
  height: 100% !important;
  float: none;
  flex-grow: 0;
}
#member-list .item > .right.floated.content .email-label {
  display: flex;
  align-items: baseline;
  font-size: 9pt;
  margin-right: 5px;
  color: #a0a0a0;
}
#member-list .item .member-bg {
  box-sizing: border-box;
  width: calc(100% - 2px * 2);
  height: calc(100% - 5px * 2);
  margin-left: -8px;
  position: absolute;
  border-radius: 4em;
  border: none;
  z-index: 1;
  -webkit-transition: 0.1s background;
  transition: 0.1s background;
}
#member-list .item:hover .member-bg {
  background: rgba(220, 221, 222, 0.5);
}
#member-list .item:active .member-bg {
  background: #c2c4c5;
  -webkit-transition: none;
  transition: none;
}
#member-list #member-selected .member-bg {
  background: #dcddde;
}
.label-entry-list-header {
  color: gray;
  margin: 5px;
}
.label-entry-list {
  display: flex;
  flex-wrap: wrap;
}
.label-entry-list .entry-label {
  cursor: pointer;
}
.label-entry-list .entry-label.selected {
  background: #dcddde;
}

.vcf-upload-root:focus {
  outline: none;
}
.vcf-upload-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.582);
  padding: 20px;
  z-index: 1000;
}
.vcf-upload-overlay-border {
  width: 100%;
  height: 100%;
  border: 20px solid white;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.club-settings {
  margin: 30px 0px;
}
.ui.checkbox,
.ui.input,
.ui.button {
  margin: 5px 5px;
}
.pay-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pay-button p {
  font-size: 8pt;
  font-style: italic;
  color: gray;
}

@media print {
  .app {
    padding: 0 !important;
  }
  #app-nav {
    display: none;
  }
  #print-menu {
    display: none;
  }
  #desktop {
    padding: 0;
  }
  tbody {
    display: block;
    page-break-inside: auto;
  }
  tfoot {
    display: table-footer-group;
  }
  #fixed-footer {
    position: fixed;
    bottom: 0;
  }
}
@media screen {
  #hidden-footer {
    display: none;
  }
  #fixed-footer {
    display: none;
  }
}
thead {
  box-sizing: border-box;
  padding-top: 25px;
  border-spacing: 25px;
}
thead div {
  margin: 25px;
}
thead div > span {
  padding-left: 25px;
}
#print-header {
  font-weight: 500;
  font-size: 24pt;
  margin: 25px;
}
#print-version .print-members .print-member {
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
          break-inside: avoid;
}
#print-version .print-members .print-member + .print-member {
  margin-bottom: 20px;
}
#print-version #print-menu .ui.item.dropdown i.icon {
  margin-left: 4px;
}

.print-member-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}
.print-member-header img.ui.image {
  margin-right: 8px;
}
.print-member-header .ui.header {
  margin: 0;
}

.club-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.club {
  max-width: 1060px;
  padding: 30px;
  width: 100%;
}
.club .club-title {
  overflow-x: hidden;
  flex-shrink: 1000 !important;
}
.club .club-title h1 {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.club .member-display {
  margin-top: 30px;
}
@media (max-width: 767.98px) {
  .club {
    padding: 5px;
  }
  .club .member-display {
    margin-top: 0px;
  }
  .club .member-display-left,
  .club .member-display-right {
    margin-bottom: 30px;
  }
}
@media (max-width: 767.98px) {
  #club-selected .club-banner {
    display: none;
  }
}

.editor-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0px;
}
.editor-view .edit-member {
  padding: 0px 10px;
  max-width: 500px;
}

.ui.modal {
  width: auto !important;
}
.password-header {
  text-align: center;
}
.visitor-password {
  min-width: 300px;
}
.visitor-password input {
  width: auto !important;
}
.visitor-password.mobile {
  top: 10px;
}

/*******************************
     User Global Variables
*******************************/
.full-page-padder {
  min-height: 100vh;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.full-page-padder.subtract-menu {
  min-height: 0;
  height: calc(100vh - 68px);
}
.full-page-padder.subtract-footer {
  min-height: 0;
  height: calc(100vh - 25px - 20px);
}
.full-page-padder.subtract-menu.subtract-footer {
  min-height: 0;
  height: calc(100vh - 68px - 14px - 25px - 20px);
}
.full-page-padder.has-background {
  background-color: coral;
}
.full-page-padder .full-page-padder-contents {
  width: 100%;
  flex-grow: 1;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.full-page-padder .shadow-caster {
  position: absolute;
  width: calc(100% + (20px * 2));
  margin-left: -20px;
  height: 1px;
  box-shadow: 0px 0px 20px 3px #000000;
  z-index: 1;
}
.full-page-padder .shadow-caster.top {
  top: -1px;
}
.full-page-padder .shadow-caster.bottom {
  bottom: -1px;
}

#login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
#login-page .login-panel {
  width: 100%;
  max-width: 345px;
}

/*******************************
     User Global Variables
*******************************/
.login-panel .login-button button {
  width: 100%;
  margin: 0;
}
.login-panel .ui.form .field {
  margin: 0px 0px 14px;
}
.login-panel .ui.form .field .ui.input {
  margin: 0;
}
.login-panel .alt-links {
  text-align: center;
  text-decoration: underline;
}
.login-panel .alt-links a {
  color: rgba(0, 0, 0, 0.308) !important;
}
.login-panel .alt-links a:not(:first-child) {
  margin-left: 5px;
}
.login-panel .extra-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-john {
  width: 700px;
}

/*******************************
     User Global Variables
*******************************/
.storefront-width-wrap {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 14px;
}
.storefront {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  min-height: 100vh;
}
.storefront > * {
  width: 100%;
}
.storefront .item {
  padding: 0;
}
.storefront .full-page-padder-contents {
  padding: 0px;
}
.menu-item {
  font-size: 14pt;
}
.menu-item-active {
  color: coral !important;
}
.storefront-nav {
  max-width: 1200px;
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}
.storefront-nav a {
  color: grey;
}
#login-button {
  color: coral !important;
  font-weight: 500;
}
.centerizer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
.centerizer.left {
  align-items: flex-start;
}
.centerizer.right {
  align-items: flex-end;
}
.billboard h1 {
  font-size: 36pt;
  color: coral;
}
.billboard p {
  font-size: 13.5pt;
}
.full-page-padder.has-background h1 {
  color: white;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
}
.full-page-padder.has-background p {
  color: white;
}
#storefront-footer {
  margin: 15px;
  text-align: center;
  color: rgba(0, 0, 0, 0.308);
}
#storefront-footer a {
  color: rgba(0, 0, 0, 0.408);
  text-decoration: underline;
}
#storefront-footer > *:not(:first-child)::before {
  content: '—';
  margin: 0px 10px;
  text-decoration: none !important;
}

/*******************************
     User Global Variables
*******************************/
.brand {
  display: flex;
  flex-direction: row;
}
.brand .brand-name {
  font-weight: bold;
  font-size: 18pt;
}
.brand .beta-tag {
  font-weight: bold;
  font-size: 8pt;
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#forgot-password-form {
  width: 100%;
  max-width: 400px;
  padding: 0px 8px;
}
#forgot-password-form .ui.button {
  margin: 0;
}
#forgot-password-form .ui.input {
  margin: 0;
}

/*******************************
     User Global Variables
*******************************/
.features-wrap {
  position: relative;
  width: 100%;
  flex-grow: 1;
  display: flex;
  padding: 20px 0px;
  z-index: 0;
}
.features {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  align-content: space-around;
  justify-content: space-evenly;
}
.feature {
  width: 100%;
  max-width: 33%;
  min-width: 275px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feature i.icon {
  margin: 10px;
  margin-bottom: 20px;
}
.feature .feature-card {
  background: white;
  width: 100%;
  flex-grow: 1;
  width: 275px;
  padding: 10px;
}
.feature .feature-card .feature-title {
  font-size: 24pt;
  text-align: center;
  margin: 10px;
}
.feature .feature-card p {
  text-align: center;
}

#home {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  flex-grow: 1;
  padding: 50px 50px;
  min-height: 700px;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
}
#home .billboard {
  flex-grow: 1;
}
#home .left.billboard {
  padding-right: 4px;
}
#signup-box {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 800px) {
  #home {
    flex-direction: column;
    justify-content: space-evenly;
  }
  #home .billboard {
    align-items: center;
    text-align: center;
    flex-grow: 0;
  }
  #home .left.billboard {
    padding-right: 0px;
  }
}

/*******************************
     User Global Variables
*******************************/
.pricing-wrap {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-evenly;
}
.pricing-plan {
  line-height: normal;
  max-width: 300px;
  margin: 8px;
  cursor: pointer;
}
.pricing-plan:hover .pricing-plan-lift,
.pricing-plan:active .pricing-plan-lift {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}
.pricing-plan .pricing-plan-name {
  text-align: center;
  font-weight: 500;
  font-size: 18pt;
  color: white;
  width: 100%;
  margin-bottom: 10px;
}
.pricing-plan .pricing-plan-subtitle {
  color: white;
  font-size: 11pt;
  padding: 0px 26px;
  font-weight: normal;
  font-style: italic;
}
.pricing-plan .pricing-plan-lift {
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-transition: 0.25s -webkit-transform;
  transition: 0.25s -webkit-transform;
  transition: 0.25s transform;
  transition: 0.25s transform, 0.25s -webkit-transform;
  color: rgba(0, 0, 0, 0.87);
}
.pricing-plan .pricing-plan-card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  margin-bottom: 0px;
}
.pricing-plan .pricing-plan-card .pricing-plan-free-trial header {
  font-size: 28pt;
  color: #4ed649;
  font-weight: 500;
}
.pricing-plan .pricing-plan-card .pricing-plan-free-trial div {
  font-size: 28pt;
}
.pricing-plan .pricing-plan-card .pricing-plan-price span {
  font-size: 28pt;
  font-weight: 500;
}
.pricing-plan .pricing-plan-card .pricing-plan-price div {
  font-size: 12pt;
}
.pricing-plan .pricing-plan-signup {
  color: white;
  background-color: #2bac27;
  width: 75%;
  padding: 20px 0px;
  font-size: 16pt;
  white-space: nowrap;
  text-align: center;
  border-bottom-left-radius: 10pt;
  border-bottom-right-radius: 10pt;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

