.editor-view {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px 0px;

	.edit-member {
		padding: 0px 10px;
		max-width: 500px;
	}
}
