.vcf-upload-root:focus {
  outline: none;
}
.vcf-upload-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.582);
  padding: 20px;
  z-index: 1000;
}
.vcf-upload-overlay-border {
  width: 100%;
  height: 100%;
  border: 20px solid white;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
