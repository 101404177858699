/*******************************
     User Global Variables
*******************************/
.pricing-wrap {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-evenly;
}
.pricing-plan {
  line-height: normal;
  max-width: 300px;
  margin: 8px;
  cursor: pointer;
}
.pricing-plan:hover .pricing-plan-lift,
.pricing-plan:active .pricing-plan-lift {
  transform: translateY(-5px);
}
.pricing-plan .pricing-plan-name {
  text-align: center;
  font-weight: 500;
  font-size: 18pt;
  color: white;
  width: 100%;
  margin-bottom: 10px;
}
.pricing-plan .pricing-plan-subtitle {
  color: white;
  font-size: 11pt;
  padding: 0px 26px;
  font-weight: normal;
  font-style: italic;
}
.pricing-plan .pricing-plan-lift {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.25s transform;
  color: rgba(0, 0, 0, 0.87);
}
.pricing-plan .pricing-plan-card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  margin-bottom: 0px;
}
.pricing-plan .pricing-plan-card .pricing-plan-free-trial header {
  font-size: 28pt;
  color: #4ed649;
  font-weight: 500;
}
.pricing-plan .pricing-plan-card .pricing-plan-free-trial div {
  font-size: 28pt;
}
.pricing-plan .pricing-plan-card .pricing-plan-price span {
  font-size: 28pt;
  font-weight: 500;
}
.pricing-plan .pricing-plan-card .pricing-plan-price div {
  font-size: 12pt;
}
.pricing-plan .pricing-plan-signup {
  color: white;
  background-color: #2bac27;
  width: 75%;
  padding: 20px 0px;
  font-size: 16pt;
  white-space: nowrap;
  text-align: center;
  border-bottom-left-radius: 10pt;
  border-bottom-right-radius: 10pt;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
