@padding: 20px;
@border: 20px;

.vcf-upload-root:focus {
	outline: none;
}

.vcf-upload-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.582);
	padding: @padding;
	z-index: 1000;

	&-border {
		width: 100%;
		height: 100%;
		border: @border solid white;
		border-radius: @border * 2;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}
