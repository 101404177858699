.edit-member {
  width: 100%;
}
.prop-handler {
  display: flex;
  flex-direction: column;
}
.prop {
  display: flex;
  align-items: center;
}
.prop .delete-button {
  transition: 0.25s width, margin;
  width: 40px;
  margin-right: 0px;
  text-align: center;
  cursor: pointer;
}
.prop .delete-button.confirm {
  width: 95px;
  margin-right: 10px;
}
.prop .input-component {
  flex-grow: 1;
}
.prop .input-component:focus-within input,
.prop .input-component:focus-within .dropdown {
  border-color: rgba(34, 36, 38, 0.35) !important;
}
.prop .input-component:hover:not(:focus-within) input,
.prop .input-component:hover:not(:focus-within) .dropdown {
  border-color: rgba(34, 36, 38, 0.2);
}
.prop .input-component input,
.prop .input-component .dropdown {
  border-radius: 1.4em;
  border-color: rgba(34, 36, 38, 0.06);
}
.prop .input-component .dropdown + input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.prop .input-component .menu {
  border-bottom-left-radius: 0.7em;
  border-bottom-right-radius: 0.7em;
}
.prop .input-component .dropdown {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}
.edit-member {
  position: relative;
}
.edit-member .edit-member-header {
  display: flex;
}
.edit-member .edit-member-header .edit-member-header-fields {
  flex-grow: 1;
}
.edit-member .prop-input-group {
  margin: 5px auto;
}
.edit-member .prop-input-group:focus-within input,
.edit-member .prop-input-group:focus-within .dropdown {
  border-color: rgba(34, 36, 38, 0.35) !important;
}
.edit-member .prop-input-group:hover:not(:focus-within) input,
.edit-member .prop-input-group:hover:not(:focus-within) .dropdown {
  border-color: rgba(34, 36, 38, 0.2);
}
.edit-member .prop-input-group .input {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.edit-member .prop-input-group .prop-handler:first-child:not(:only-child) input {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.edit-member .prop-input-group .prop-handler:last-child:not(:only-child) .input {
  margin-top: -1px !important;
}
.edit-member .prop-input-group .prop-handler:last-child:not(:only-child) input {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.edit-member .prop-input-group .prop-handler:not(:first-child):not(:last-child):not(:only-child) .input {
  margin-top: -1px !important;
}
.edit-member .prop-input-group .prop-handler:not(:first-child):not(:last-child):not(:only-child) input {
  border-radius: 0 !important;
}
.edit-member .prop-handler .add-button {
  margin-top: 10px;
}
.edit-member .saving-dimmer-wrap {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
}
.edit-member .saving-dimmer-wrap .saving-dimmer {
  background: rgba(255, 255, 255, 0.85);
  width: 100%;
  height: 100%;
  max-height: 100vh;
  position: sticky;
  top: 0px;
}
.edit-member .edit-member-menu {
  position: sticky;
  top: 5px;
  z-index: 10;
  height: 38px;
}
.edit-member-image-dimmer {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  overflow: hidden;
  cursor: pointer;
}
.edit-member-image-dimmer .edit-member-image {
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center !important;
}
.edit-member-image-file-input {
  display: none;
  width: 0px;
  height: 0px;
  position: fixed;
  margin: 0;
  padding: 0;
}
.edit-member-popup {
  margin: 0 !important;
}
.edit-member-popup .item {
  margin: 0 !important;
}
.ui.selection.dropdown.type-picker {
  flex-grow: 0;
  flex-shrink: 2;
  min-width: 10px;
}
