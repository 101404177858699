@media print {
  .app {
    padding: 0 !important;
  }
  #app-nav {
    display: none;
  }
  #print-menu {
    display: none;
  }
  #desktop {
    padding: 0;
  }
  tbody {
    display: block;
    page-break-inside: auto;
  }
  tfoot {
    display: table-footer-group;
  }
  #fixed-footer {
    position: fixed;
    bottom: 0;
  }
}
@media screen {
  #hidden-footer {
    display: none;
  }
  #fixed-footer {
    display: none;
  }
}
thead {
  box-sizing: border-box;
  padding-top: 25px;
  border-spacing: 25px;
}
thead div {
  margin: 25px;
}
thead div > span {
  padding-left: 25px;
}
#print-header {
  font-weight: 500;
  font-size: 24pt;
  margin: 25px;
}
#print-version .print-members .print-member {
  break-inside: avoid;
}
#print-version .print-members .print-member + .print-member {
  margin-bottom: 20px;
}
#print-version #print-menu .ui.item.dropdown i.icon {
  margin-left: 4px;
}
