.print-member-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}
.print-member-header img.ui.image {
  margin-right: 8px;
}
.print-member-header .ui.header {
  margin: 0;
}
