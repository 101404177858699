.code-block {
	overflow-x: auto;
	background: lightgray !important;
	box-sizing: border-box;
	max-width: 100%;
}

pre {
	min-height: 100px;
}

code {
}
