@media print {
	.app {
		padding: 0 !important;
		// width: 100vw;
		// outline: 1px solid blueviolet;
	}

	.app .content {
		// outline: 1px solid red;
	}

	#app-nav {
		display: none;
	}

	#print-menu {
		display: none;
	}

	#desktop {
		padding: 0;
	}

	.content {
		// max-width: 100vw !important;
	}

	tbody {
		// height: 100%;
		display: block;
		page-break-inside: auto;
	}

	tfoot {
		// outline: 1px solid green;
		display: table-footer-group;
	}

	// hack for printing the footer at the bottom of each page.

	// #hidden-footer {
	// 	display: table-footer-group;

	// 	// height: 1em !important;
	// }
	#fixed-footer {
		position: fixed;
		bottom: 0;
	}
}

@media screen {
	#hidden-footer {
		display: none;
	}
	#fixed-footer {
		display: none;
	}
}

thead {
	// outline: 1px solid pink;
	box-sizing: border-box;
	padding-top: 25px;

	border-spacing: 25px;

	div {
		margin: 25px;
		& > span {
			padding-left: 25px;
		}
	}
}

#print-header {
	// display: table-header-group;
	font-weight: 500;
	font-size: 24pt;
	margin: 25px;
}

#print-version {
	.print-members {
		// margin-top: 25px;

		.print-member {
			break-inside: avoid;

			& + .print-member {
				margin-bottom: 20px;
			}
		}
	}

	#print-menu {
		.ui.item.dropdown i.icon {
			margin-left: 4px;
		}
	}
}
