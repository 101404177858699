.print-member-header {
	display: flex;
	flex-direction: row;
	align-items: center;

	margin-bottom: 8px;

	img.ui.image {
		margin-right: 8px;
	}

	.ui.header {
		margin: 0;
	}
}
