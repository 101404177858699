.member-display {
  display: flex;
  width: 100%;
  flex-direction: row;
  min-height: 600px;
}
.member-display [class^='member-display-'] {
  flex-grow: 1;
  width: 50%;
}
.member-display-left,
.member-display-right {
  background: white;
}
.member-display-left {
  padding-right: 2.5px;
}
.member-display-right {
  padding-left: 2.5px;
  z-index: 10;
  top: 0px;
}
.member-display-right > div[style='position: sticky; top: 10px;'] {
  top: 0px !important;
}
.entry-label {
  margin: 1.7px !important;
}
@media (max-width: 767.98px) {
  .member-display {
    position: relative;
    padding-top: 5px;
  }
  .member-display .member-display-left,
  .member-display .member-display-right {
    position: absolute;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .member-display.selected .member-display-left {
    display: none;
  }
}
