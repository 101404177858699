@import 'fonts';
@import 'mixins';
@import 'mobile';

body {
	margin: 0;
	// font-family: @font-main;
	overflow: auto;
}

#root {
	width: 100%;
}

code {
	font-family: @font-code;
}

.nav-link {
	white-space: nowrap;
}

// fixing bad css in react-semantic-toasts
#root .ui-alerts.top-center {
	left: 0;
	margin-left: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	div {
		max-width: 444px;
	}
}
