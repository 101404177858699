@import '../../semantic-ui/site/globals/site.variables';
@import '../../mixins';

@member-bg-color: @listSelectionColor;

.letter-header {
	background: @primaryColor;
	font-size: 14pt;
	padding: 5px;
	position: sticky !important;
	top: 50px;
	border-radius: 1em + 10px;
	padding-left: 0.75em;
	line-height: 1em;
	z-index: 2;
	// .mobile({border-radius: 0; padding-left: 0.5em;});
}

#member-list {
	.member-list-menu {
		position: sticky !important;
		top: 5px;
		z-index: 10;
		// border-radius: 30px;
		// padding: 0px 20px;
		margin-bottom: 5px;

		.left.menu {
			flex-grow: 1;
			cursor: text;
		}

		div.search {
			margin-left: 10px;
			flex-grow: 1;
			display: flex;
			align-items: center;

			input {
				margin: 0px 5px;
			}

			.x.link.icon {
				cursor: pointer;
			}
		}
	}

	.list {
		padding: 0;
	}
	.image {
		flex-shrink: 0;
		// padding-right: 0;
	}
	.item {
		padding: 10px;
		display: flex;
		position: relative;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;

		div,
		img {
			z-index: 1;
		}

		img {
			border: 2px solid darken(@member-bg-color, 5);
		}

		.ui.image {
			padding-right: 0;
		}

		.header {
			font-weight: normal;
			font-size: 13pt;
		}
		.header,
		.description {
		}

		.member-name {
			flex-grow: 1;
			overflow: hidden;
			text-overflow: ellipsis;
			& * {
				width: 100%;
				white-space: nowrap;

				min-width: 0;
			}
		}
		& > .right.floated.content {
			display: flex;
			align-items: center;
			height: 100% !important;
			float: none;
			flex-grow: 0;

			.email-label {
				display: flex;
				align-items: baseline;
				font-size: 9pt;
				margin-right: 5px;
				color: rgb(160, 160, 160);
			}
		}

		.member-bg {
			@bg-margin-v: 5px;
			@bg-margin-h: 2px;
			box-sizing: border-box;
			width: calc(100% - @bg-margin-h * 2);
			height: calc(100% - @bg-margin-v * 2);
			margin-left: -10px + @bg-margin-h;
			position: absolute;
			border-radius: 4em;
			border: none;
			z-index: 1;
			transition: 0.1s background;
		}
		&:hover .member-bg {
			background: fade(@member-bg-color, 50%);
		}
		&:active .member-bg {
			background: darken(@member-bg-color, 10%);
			transition: none;
		}
	}

	#member-selected .member-bg {
		background: @member-bg-color;
	}
}

.label-entry-list-header {
	color: gray;
	margin: 5px;
}

.label-entry-list {
	display: flex;
	// justify-content: space-evenly;
	flex-wrap: wrap;

	.entry-label {
		cursor: pointer;
		&.selected {
			background: @member-bg-color;
		}
	}
}
