/*******************************
     User Global Variables
*******************************/
.full-page-padder {
  min-height: 100vh;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.full-page-padder.subtract-menu {
  min-height: 0;
  height: calc(100vh - 68px);
}
.full-page-padder.subtract-footer {
  min-height: 0;
  height: calc(100vh - 25px - 20px);
}
.full-page-padder.subtract-menu.subtract-footer {
  min-height: 0;
  height: calc(100vh - 68px - 14px - 25px - 20px);
}
.full-page-padder.has-background {
  background-color: coral;
}
.full-page-padder .full-page-padder-contents {
  width: 100%;
  flex-grow: 1;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.full-page-padder .shadow-caster {
  position: absolute;
  width: calc(100% + (20px * 2));
  margin-left: -20px;
  height: 1px;
  box-shadow: 0px 0px 20px 3px #000000;
  z-index: 1;
}
.full-page-padder .shadow-caster.top {
  top: -1px;
}
.full-page-padder .shadow-caster.bottom {
  bottom: -1px;
}
