@import '../../mixins.less';
@club-padding: 30px;

.club-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.club {
	max-width: 1000px + (@club-padding * 2);
	padding: @club-padding;
	width: 100%;

	.club-title {
		overflow-x: hidden;
		flex-shrink: 1000 !important;

		h1 {
			width: 100%;
			overflow-x: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.member-display {
		margin-top: @club-padding;
	}

	@media (max-width: @mobile-width) {
		padding: 5px;

		.member-display {
			margin-top: 0px;
		}

		.member-display-left,
		.member-display-right {
			margin-bottom: @club-padding;
		}
	}
}
@media (max-width: @mobile-width) {
	#club-selected {
		.club-banner {
			display: none;
		}
	}
}
